.musician-page-background-0 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.898), rgb(0, 0, 0)),
    url('https://storage.googleapis.com/demo-profile/images/wood-texture-background.jpg');
}

.musician-page-background-1 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.898), rgb(0, 0, 0)),
    url('https://storage.googleapis.com/demo-profile/images/colorful-wood-background.jpg');
}

.musician-page-background-2 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.898), rgb(0, 0, 0)),
    url('https://storage.googleapis.com/demo-profile/images/silver-texture-background.jpg');
}

.musician-mobile-background-0 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(0, 0, 0)),
    url('https://storage.googleapis.com/demo-profile/images/wood-texture-background.jpg');
}

.musician-mobile-background-1 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(0, 0, 0)),
    url('https://storage.googleapis.com/demo-profile/images/colorful-wood-background.jpg');
}

.musician-mobile-background-2 {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(0, 0, 0)),
    url('https://storage.googleapis.com/demo-profile/images/silver-texture-background.jpg');
}
