.musician-about-card {
  background-color: aqua;
  border-radius: 8px;
  overflow-y: auto;
  background: linear-gradient(180deg, #202020 0%, #000000 100%);

  @media only screen and (max-width: 1180px) {
    min-width: 370px;
  }
}

.musician-about-text {
  font-size: 16px;
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.offerings-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center;
}

.offerings-pill {
  align-self: center;
  justify-self: center;
  padding: 10px;
  border-radius: 23px;
  background-color: #555555;
  margin: 0;
}

.highlight-offering {
  border: solid;
  border-color: white;
}
