.ant-float-btn-body {
  width: auto !important;
  height: 50px !important;
  background-color: #d9d9d9 !important;
}

.ant-float-btn-group {
  width: 50px !important;

  & > button {
    width: 50px !important;
    height: 50px !important;
  }
}

.ant-float-btn-icon {
  width: 25px !important;
  height: 25px !important;
}

.anticon-close {
  height: 25px;
}
