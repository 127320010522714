.musician-contact-form-button {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  min-height: 50px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: none;
  background-color: #d9d9d9;
  cursor: pointer;
}
