@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-family: 'Roboto';
  color: white;
}

@font-face {
  font-family: 'Roboto';
  src: url(../src/assets/fonts/RobotoMono-VariableFont_wght.ttf) format('truetype');
}

.ant-modal-content {
  background-color: rgba(0, 0, 0, 0.762) !important;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
}

.ant-modal-body {
  display: flex !important;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}

.ant-modal-mask {
  background-color: transparent !important;
}

.ant-divider {
  background-color: #ffffff !important;
}

.text-input {
  color: #ffffff !important;
  background-color: transparent !important;
  border-top: transparent !important;
  border-right: transparent !important;
  border-left: transparent !important;
  border-bottom: #ffffff solid 1px !important;
  font-family: 'Roboto' !important;
  font-size: 18px !important;
  text-align: center !important;
  color: white !important;
  border-radius: 0 !important;

  &:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }
}

.text-input::placeholder {
  font-size: 18px !important;
  font-family: 'Roboto' !important;
  text-align: center !important;
  color: white !important;
}

.text-area {
  color: #ffffff !important;
  background-color: transparent !important;
  border-top: transparent !important;
  border-right: transparent !important;
  border-left: transparent !important;
  border-bottom: #ffffff solid 1px !important;
  font-family: 'Roboto' !important;
  font-size: 18px !important;
  text-align: start !important;
  color: white !important;
  border-radius: 0 !important;

  &:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }
}

.text-area::placeholder {
  font-size: 18px !important;
  font-family: 'Roboto' !important;
  text-align: start !important;
  color: white !important;
}

.title-small {
  font-size: 20px;

  @media only screen and (max-width: 1180px) {
    font-size: 18px;
  }
}

.title-medium {
  font-size: 40px;

  @media only screen and (max-width: 1180px) {
    font-size: 30px;
  }
}
