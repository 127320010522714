.musician-location-wrapper {
  display: flex;
  gap: 20px;
}

.musician-location {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 200;
}

.location-name {
  margin: 0;
}
