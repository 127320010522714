.upload-buttons {
  display: flex;
  position: absolute;
  gap: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.upload-file {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: none;
  background-color: #d9d9d9;
}

.musician-image {
  cursor: pointer;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  transition: 0.3s linear;
  transform: scale(1);
  &:hover {
    transition: 0.3s linear;
    transform: scale(1.02);
  }
}

.musician-image-animating {
  cursor: pointer;
  object-fit: cover;
  border-radius: 50%;
  animation: musician-image-zoom infinite 0.8s linear;
}

@keyframes musician-image-zoom {
  0% {
    transform: scale(1.02);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.02);
  }
}
