.genres-card {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 8px;
  overflow-y: auto;
  max-height: 340px;
  background: linear-gradient(180deg, #202020 0%, #000000 100%);
  padding-left: 15px;
  padding-right: 15px;
}

.genres-pills {
  display: flex;
  flex-wrap: wrap;
}

.genre {
  display: flex;
  gap: 10px;
  margin-left: 7px;
  margin-right: 7px;
  margin-top: 0;
  padding: 8px 20px;
  border-radius: 23px;
  background-color: #555555;
}

.add-genre {
  color: white;
  background-color: #555555;
  border: none;
  &:focus {
    color: white;
    background-color: #555555;
    border: none;
  }
}
.musician-genres-done-button {
  padding: 0;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: none;
  background-color: #d9d9d9;
}
